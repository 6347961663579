import React from 'react';
import i18next from 'i18next';
import MenuItem from '@mui/material/MenuItem';
import { CustomerContext } from "../common/CustomerContext.js";
import { exportProductJSON, exportProductXML, getCookie } from '../common/helpers/Common'
import LinearProgress from '@mui/material/LinearProgress';


class MenuItemComponent extends React.Component {
  static contextType = CustomerContext;
  constructor(props) {
    super(props);
    this.state = {
      product: props.product
    };
  }

  handleOptionClick = (option) => {
    let snackBarMessage = '';
    switch (option) {
      case 'json':
          snackBarMessage = `Trade item JSON ${this.props.product['gtin']} 
            ${i18next.t('searchResults.snackBarMessageOnDownload')}`
          this.props.handleSnackBarOpen(snackBarMessage)
          exportProductJSON(this.props.product)
          break;
      case 'xml':
          snackBarMessage = `Trade item XML ${this.props.product['gtin']} 
            ${i18next.t('searchResults.snackBarMessageOnDownload')}`
          this.props.handleSnackBarOpen(snackBarMessage)
          exportProductXML(this.props.product)
          break;
      case 'cin':
          this.props.downloadSingleCIN(this.props.recordIndex)
          break;  
      case 'nested-cin':
          this.props.downloadNestedCIN(this.props.recordIndex)
          break;
      case 'send-to-zsm':
        this.props.sendToZsm(this.props.recordIndex)
        break;
      case 'infoxml':
          this.props.downloadInformaticaXML(this.props.recordIndex)
          break;
      case 'kap':
          this.props.downloadKauflandArticlepassMoreButton(this.props.recordIndex)
          break;
      case 'tradeItemHierarchy':
          this.props.onTradeItemHierarchyProduct(this.props.product)
          this.props.toggleTradeItemHierarchyModal()
          this.props.onClose()
          break;   
      case 'originalMessage':
          this.props.downloadOriginalMessage(this.props.recordIndex)
          break;   
      default:
        break;
    }
  };

  render() {
    const informaticaXmlID = "informatica-xml-" + this.props.recordIndex
    const singleCINXML = "single-cin-xml-" + this.props.recordIndex
    const nestedCINXML = "nested-cin-xml-" + this.props.recordIndex
    const sendToZsm = "send-to-zsm-" + this.props.recordIndex
    const kauflandArticlePassID = "kaufland-article-pass-" + this.props.recordIndex
    const tradeItemJsonID = "trade-item-json-" + this.props.recordIndex
    const tradeItemXmlID = "trade-item-xml-" + this.props.recordIndex
    const tradeItemHierarchyID = "trade-item-hierarchy-" + this.props.recordIndex
    const originalMessageID = "original-message-" + this.props.recordIndex

    return (
      <div id="menu-item">
        {process.env.REACT_APP_SOURCE === 'aldi' && 
          <div style={{width: "100%"}}>
            <MenuItem id={informaticaXmlID} disabled={this.props.product.disableInformaticaXMLMenuItem} onClick={() => this.handleOptionClick('infoxml')}>
              <div style={{fontSize: "1rem"}}>Informatica XML</div>
            </MenuItem>
            <div hidden={this.props.product.hideInformaticaXMLLinearProgress}><LinearProgress /></div>
          </div>
        }
        {process.env.REACT_APP_SOURCE === 'schwarz' && ['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE) &&
          <div style={{width: "100%"}}>
            <MenuItem id={singleCINXML} disabled={this.props.product.disableCINMenuItem} onClick={() => this.handleOptionClick('cin')}>
              <div style={{fontSize: "1rem"}}>Unnested GDSN 3.1 CIN</div>
            </MenuItem>
            <div hidden={this.props.product.hideCINLinearProgress}><LinearProgress /></div>
          </div>
        }
        {process.env.REACT_APP_SOURCE === 'schwarz' && ['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE) && this.props.product['source'] === 'markant' &&
          <div style={{width: "100%"}}>
            <MenuItem id={nestedCINXML} disabled={this.props.product.disableNestedCINMenuItem} onClick={() => this.handleOptionClick('nested-cin')}>
              <div style={{fontSize: "1rem"}}>Nested GDSN 3.1 CIN</div>
            </MenuItem>
            <div hidden={this.props.product.hideNestedCINLinearProgress}><LinearProgress /></div>
          </div>
        }
        {process.env.REACT_APP_SOURCE === 'schwarz' && ['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE) && this.props.product['source'] === 'markant' &&
          <div style={{ width: "100%" }}>
            <MenuItem id={sendToZsm} disabled={this.props.product.disableSendToZSMMenuItem} onClick={() => this.handleOptionClick('send-to-zsm')}>
              <div style={{ fontSize: "1rem" }}>Send To ZSM</div>
            </MenuItem>
            <div hidden={this.props.product.sendToZSMLinearProgress}><LinearProgress /></div>
          </div>
        }
        {process.env.REACT_APP_SOURCE === 'schwarz' && ['mvp', 'a', 'q'].includes(process.env.REACT_APP_MODE) &&
          <div style={{width: "100%"}}>
            <MenuItem id={kauflandArticlePassID} disabled={this.props.product.disableKArticlePassMenuItem} onClick={() => this.handleOptionClick('kap')}>
              <div style={{fontSize: "1rem"}}>KFL article pass</div>
            </MenuItem>
            <div hidden={this.props.product.hideKArticlePassLinearProgress}><LinearProgress /></div>
          </div>
        }
        <MenuItem id={tradeItemJsonID} onClick={() => this.handleOptionClick('json')}>
          <div style={{fontSize: "1rem"}}>GDSN JSON</div>
        </MenuItem>
        <MenuItem id={tradeItemXmlID} onClick={() => this.handleOptionClick('xml')}>
           <div style={{fontSize: "1rem"}}>GDSN XML</div>
        </MenuItem>
        {['GDSN', 'markant'].includes(this.props.product['source']) && process.env.REACT_APP_MODE !== 'lidl' && // For now, hierarchies are available for GDSN items only
        <MenuItem id={tradeItemHierarchyID} onClick={() => this.handleOptionClick('tradeItemHierarchy')}>
          <div style={{fontSize: "1rem"}}>Show hierarchy</div>
        </MenuItem>
        }
        {!['OFF'].includes(this.props.product['source']) && getCookie('is_message_download_allowed') === 'true' &&
          <div style={{width: "100%"}}>
            <MenuItem id={originalMessageID} disabled={this.props.product.disableMessageDownloadMenuItem} onClick={() => this.handleOptionClick('originalMessage')}>
              <div style={{fontSize: "1rem"}}>Original Message</div>
            </MenuItem>
            <div hidden={this.props.product.hideMessageDownloadLinearProgress}><LinearProgress /></div>
          </div>
        }
      </div>
    );
  }
}

export default (MenuItemComponent);

